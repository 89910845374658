.top_left {
  border: 2px solid black;
  border-top-left-radius: 6px;
  border-right: none;
  border-bottom: 0.5px solid lightgrey;
  font-size: 14px;
}
.bottom_left {
  border: 2px solid black;
  border-bottom-left-radius: 6px;
  border-right: none;
  border-top: none;
  font-size: 14px;
}
.top_right {
  border: 2px solid black;
  border-top-right-radius: 6px;
  border-left: none;
  border-bottom: 0.5px solid lightgrey;
  font-size: 14px;
}
.bottom_right {
  border: 2px solid black;
  border-bottom-right-radius: 6px;
  border-left: none;
  border-top: none;
  font-size: 14px;
}
.border_left {
  border-left: 2px solid black;
  font-size: 14px;
}
.border_right {
  border-right: 2px solid black;
  font-size: 14px;
}
.border_top {
  border-top: 2px solid black;
  font-size: 14px;
}
.border_bottom {
  border-bottom: 2px solid black;
  font-size: 14px;
}
td {
  border-bottom: 0.5px solid lightgrey;
}
