.container {
  width: 450px;
  height: 466px;
  background: #ffffff;
  border: 1px solid #000000;
  border-radius: 8px;
}
.border_bottom {
  border-bottom: 1px solid #000000;
}
.border_bottom img {
  width: 108px;
  height: 109px;
}
.flex_middle {
  display: flex;
  justify-content: center;
  align-items: center;
}
.flex_column {
  flex-direction: column;
}
.input_login {
  width: 266px;
  border: none;
  height: 22px;
  border-bottom: 1px solid #000000;
}
.login_btn {
  width: 266px;
  height: 46px;
  background: #7ccbfa;
  border-radius: 100px;
  display: flex;
  align-items: center;
  margin-top: 37px;
  justify-content: center;
  cursor: pointer;
}
