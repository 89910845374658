.pop_up_container {
  position: fixed;
  background: #00000050;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
}
.pop_up_wrapper {
  position: relative;
  width: 23%;
  margin: 0 auto;
  height: auto;
  margin-top: calc(100vh - 79vh - 20px);
  background: #fff;
  border-radius: 4px;
  padding: 20px;
  border: 1px solid #999;
  overflow: auto;
}
.flex_column_center {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.OK_button {
  height: 32px;
  background: dodgerblue;
  border: none;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  width: 80px;
}
.border_1px {
  border-bottom: 1px solid lightgrey;
  font-weight: bold;
  font-size: 18px;
}
.flex_space_between {
  display: flex;
  justify-content: space-between;
}
.flex_align_center {
  align-items: center;
  display: flex;
}
.input_box {
  width: 180px;
  height: 26px;
}
.container_button {
  width: 29%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin-top: 24px;
}
.reset_button_add_user {
  height: 32px;
  margin-right: 12px;
  cursor: pointer;
  background: white;
  border: 1px solid black;
  border-radius: 4px;
  min-width: 58px;
}
.save_button {
  height: 32px;
  background: dodgerblue;
  border: none;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  min-width: 58px;
}
.text_area {
  width: 376px;
  height: 82px;
  resize: none;
  font-family: sans-serif;
  padding: 12px;
}
