.container_dashboard {
  border: 1px solid lightgray;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 16px;
  width: 100%;
}
.table_dashboard {
  border: 1px solid lightgray;
  border-radius: 8px;
  padding: 8px;
  border-spacing: 0;
  width: 100%;
}
td,
th {
  padding: 12px 12px 12px 12px;
  text-align: left;
  vertical-align: top;
}
th {
  border-bottom: 1px solid lightgray;
}
.edit_btn {
  margin: 0px 0px 0px 12px;
  padding: 0 6px;
  background: dodgerblue;
  color: white;
  border-radius: 4px;
  align-items: center;
  display: flex;
  cursor: pointer;
}
.flex_column {
  display: flex;
  flex-direction: column;
  margin-top: 18px;
}
.logout_btn {
  margin: 12px 0;
  border-radius: 6px;
  background: dodgerblue;
  padding: 6px 12px;
  cursor: pointer;
  color: white;
}
.flex_space_between {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.export_btn {
  background: #1d6f42;
  margin-bottom: 18px;
  margin-left: 12px;
  cursor: pointer;
  color: white;
  padding: 6px 6px 0 6px;
  border-radius: 6px;
  font-size: 14px;
}
.wrapper_menu {
  display: flex;
  align-items: center;
  margin-bottom: 18px;
  justify-content: space-between;
}
.wrapper_logout {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
@media only screen and (max-width: 600px) {
  .wrapper_logout {
    max-width: 30%;
  }
  .wrapper_menu {
    justify-content: center;
  }
}
