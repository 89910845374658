p {
  margin: 12px 0 6px 0;
}
.container_popup {
  position: fixed;
  background: #00000050;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
}
.wrapper_popup {
  position: relative;
  width: 46%;
  margin: 0 auto;
  height: auto;
  margin-top: calc(100vh - 95vh - 32px);
  background: #fff;
  border-radius: 4px;
  padding: 18px;
  border: 1px solid #999;
  overflow: auto;
}
.flex_space_between {
  display: flex;
  justify-content: space-between;
}
.input_box {
  width: 180px;
  height: 26px;
}
.text_area {
  width: 394px;
  height: 82px;
  resize: none;
  font-family: sans-serif;
  padding: 12px;
}
.container_button {
  width: 180px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin-top: 24px;
}
.cancel_button {
  height: 32px;
  margin-right: 12px;
  cursor: pointer;
  background: white;
  border: 1px solid black;
  border-radius: 4px;
  min-width: 58px;
}
.save_button {
  height: 32px;
  background: dodgerblue;
  border: none;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  min-width: 58px;
}
.flex_align_center {
  align-items: center;
  display: flex;
}
@media only screen and (max-width: 600px) {
  .container_popup {
    overflow: auto;
  }
  .wrapper_popup {
    width: 80%;
  }
}
