.flex_space_between {
  display: flex;
  justify-content: space-between;
}
.reset_data_button {
  margin: 0 0 18px 12px;
  align-items: center;
  display: flex;
  font-size: 14px;
  border-radius: 6px;
  background: red;
  padding: 6px;
  cursor: pointer;
  color: white;
}
.popup_container {
  position: fixed;
  background: #00000050;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
}
.popup_wrapper {
  position: relative;
  width: 30%;
  margin: 0 auto;
  height: auto;
  margin-top: calc(100vh - 60vh - 20px);
  background: #fff;
  border-radius: 4px;
  padding: 20px;
  border: 1px solid #999;
  overflow: auto;
}
.radio_employee_type {
  align-items: center;
  display: flex;
  margin-right: 12px;
}
.cancel_button {
  margin: 12px 6px 0 6px;
  cursor: pointer;
  padding: 6px 12px;
  border: 1px solid black;
  border-radius: 4px;
  background: white;
}
.reset_button {
  margin: 12px 6px 0 6px;
  padding: 6px 12px;
  border: none;
  color: white;
  border-radius: 4px;
  cursor: pointer;
}
