.flex_absensi {
  display: flex;
  justify-content: space-between;
}

.wrapper_filter_absensi {
  display: flex;
}

@media only screen and (max-width: 600px) {
  .flex_absensi {
    flex-wrap: wrap;
  }
  .wrapper_filter_absensi {
    flex-wrap: wrap;
  }
}
