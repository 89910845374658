.menu_active {
  margin-right: 12px;
  margin-left: 6px;
  font-size: 18px;
  border-bottom: 3px solid dodgerblue;
  line-height: 2;
  font-weight: bold;
  cursor: pointer;
}

.menu {
  margin-left: 6px;
  margin-right: 12px;
  font-size: 16px;
  cursor: pointer;
}

.wrapper_sub_menu {
  display: flex;
  align-items: center;
}
@media only screen and (max-width: 600px) {
  .wrapper_sub_menu {
    flex-wrap: wrap;
    max-width: 50%;
  }
}
